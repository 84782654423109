<template>
  <div class="earningSummary">
    <ul v-if="withdrawStats">
      <li>
        {{$t('Net Income')}}
        <span v-html="formatPrice(withdrawStats.income)"></span>
      </li>
      <li>
        {{$t('Withdrawan')}}
        <span v-html="formatPrice(withdrawStats.withdrawn)"></span>
      </li>
      <li>
        {{$t('Pending Clearance')}}
        <span v-html="formatPrice(withdrawStats.pending)"></span>
      </li>
    </ul>
  </div>
</template>

<script>
import { WITHDRAW_STATS } from "@/graphql/user/query";
export default {
  apollo: {
    withdrawStats: {
      query: WITHDRAW_STATS,
    },
  },
};
</script>

<style lang="scss" scoped>
.earningSummary {
  margin-bottom: rem(16px);
  position: relative;
  ul {
    background: #fff;
    padding: rem(16px) rem(100px) rem(26px) rem(75px);
    list-style: none;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width: 991px) {
      padding: rem(16px) rem(50px) rem(26px);
    }
    @media screen and (max-width: 767px) {
      flex-wrap: wrap;
      padding: rem(25px) rem(20px) 0;
    }
    li {
      font-size: rem(18px);
      color: var(--textPrimary);
      font-weight: 700;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      @media screen and (max-width: 767px) {
        width: 48%;
        margin-bottom: rem(25px);
        text-align: center;
        &:nth-child(odd) {
          margin-right: rem(10px);
        }
      }
      span {
        color: var(--primary);
        display: block;
        margin-top: rem(17px);
        font-size: rem(40px);
        ::v-deep {
          i {
            font-style: normal;
            font-size: rem(26px);
          }
        }
      }
    }
  }
}
</style>
